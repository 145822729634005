import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={14} viewBox="0 0 16 14" {...props}>
      <title>{'paper-plane'}</title>
      <path
        d="M14.826.384a.384.384 0 01.52.429l-2.34 11.918a.756.756 0 01-.735.6.74.74 0 01-.426-.134l-2.819-1.95-1.988 2.2a.38.38 0 01-.414.107.384.384 0 01-.26-.338L6.159 9.34.534 7.64A.744.744 0 010 6.968a.755.755 0 01.451-.735zm-.504 1.662L7.129 9.002l5.129 3.55 2.064-10.506zM6.95 9.81l.129 2.445 1.312-1.45-1.44-.995zm6.583-8.07L.803 6.92l5.618 1.697 7.112-6.878z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default SvgComponent
