import React, { ReactElement, useState } from 'react'
import ContactUs from '../../components/Layout/ContactUs'

import SEO from '../../components/SEO/SEO'

import heroImage from '../../assets/images/contact-us/contact.png'

import ContactForm from '../../components/ContactUsPage/ContactForm'

import { graphql, useStaticQuery } from 'gatsby'

const ContactUsPage = (): ReactElement => {
  const [termsModal, setTermsModal] = useState(false)
  const [privacyModal, setPrivacyModal] = useState(false)
  const [submitModal, setSubmitModal] = useState(false)

  const contactUsHeroBanner = useStaticQuery(graphql`
    query {
      heroBanner(title: { eq: "Contact Us" }) {
        image
      }
    }
  `)

  return (
    <>
      <ContactUs
        termsModal={termsModal}
        privacyModal={privacyModal}
        submitModal={submitModal}
        onCloseTerms={() => setTermsModal(false)}
        onClosePrivacy={() => setPrivacyModal(false)}
        onCloseSubmit={() => setSubmitModal(false)}
        heroImage={contactUsHeroBanner.heroBanner ? contactUsHeroBanner.heroBanner.image : ''}
      >
        <SEO title="CONTACT US - ALISI | Ayala Land" />
        <ContactForm
          onOpenTerms={() => setTermsModal(true)}
          onOpenPrivacy={() => setPrivacyModal(true)}
          onOpenSubmit={() => setSubmitModal(true)}
        />
      </ContactUs>
    </>
  )
}

export default ContactUsPage
