/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios'
import { getNames } from 'country-list'
import React, { useState } from 'react'
import FloatingLabelInput from 'react-floating-label-input'
import ReCAPTCHA from 'react-google-recaptcha'

import ContentNav from '../../ContentNav'
import Content from '../../Content'
import './ContactForm.scss'
import { links } from '../links'
import Dropdown from '../../Dropdown/Dropdown'
import { typeOfInquiries } from './data'
import PaperPlaneIcon from '../../../assets/icons/PaperPlane'
import { isContact, isEmail, capitalizeWords } from '../../Helpers/helpers'
import { alphaOnly, numericOnly, alphaNumericWithSpecialCharacters, preventInput } from '../../Common/Validator'

const ALISI_BRAND_ID: any = process.env.GATSBY_API_ALISI_BRAND_ID
const API_URL: any = process.env.GATSBY_API_URL

interface ErrorProps {
  firstName?: string | null
  middleName?: string | null
  lastName?: string | null
  email?: string | null
  general?: string | null
  phoneNumber?: string | null
  message?: string | null
  inquiry?: string | null
  location?: string | null
  typeOfInquiry?: string | null
  terms?: string | null
  reCaptcha?: string | null
}

const ContactForm = (props: any): JSX.Element => {
  const [typeOfInquiry, setTypeOfInquiry] = useState('')
  const [typeOfInquiryActive, setTypeOfInquiryActive] = useState('')

  const [location, setLocation] = useState('')
  const [locationActive, setLocationActive] = useState('')
  const [filterLocation, setFilterLocation] = useState('')

  const [messageFocus, setMessageFocus] = useState('')

  const [terms, setTerms] = useState<boolean>(false)

  const [captcha, setCaptcha] = useState<boolean>(false)

  const countries = getNames()

  const [errors, setErrors] = useState<ErrorProps>({})

  const filteredCountries = countries.filter((name) => name.includes(capitalizeWords(filterLocation)))

  const handleTypeChange = (type: string): void => {
    setErrors((oldErrors) => ({
      ...oldErrors,
      typeOfInquiry: null
    }))
    setTypeOfInquiryActive('')
    setTypeOfInquiry(type)
  }

  const handleLocationChange = async (country: string) => {
    setErrors((oldErrors) => ({
      ...oldErrors,
      location: null
    }))
    await setLocation(country)
    await setLocationActive('')
    await setFilterLocation('')
  }

  const handleTerms = async () => {
    setErrors((oldErrors) => ({
      ...oldErrors,
      terms: null
    }))
    setTerms(!terms)
  }

  const [fields, setFields] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: '',
    inquiry: ''
  })

  const handleChange = (events: any): void => {
    events.persist()

    const name = events.target.name
    const value = events.target.value

    setErrors((oldErrors) => ({
      ...oldErrors,
      [name]: null
    }))
    setFields((oldFields) => ({
      ...oldFields,
      [name]: value
    }))
  }

  const handleSubmit = (): void => {
    const fieldsError: any = {}

    if (fields.firstName.trim() === '') {
      fieldsError.firstName = 'This field is required!'
    }

    if (fields.lastName.trim() === '') {
      fieldsError.lastName = 'This field is required!'
    }

    if (!isEmail(fields.email)) {
      fieldsError.email = 'Please provide a proper email address.'
    }

    if (location.trim() === '') {
      fieldsError.location = 'Please select location!'
    }

    if (!isContact(fields.phoneNumber)) {
      fieldsError.phoneNumber = 'Please provide a proper contact number.'
    }

    if (typeOfInquiry.trim() === '') {
      fieldsError.typeOfInquiry = 'Please select Inquiry!'
    }

    if (typeOfInquiry === 'Others' && fields.inquiry.trim() === '') {
      fieldsError.inquiry = 'This field is required!'
    }

    if (fields.message.trim() === '') {
      fieldsError.message = 'Please add message!'
    }

    if (!terms) {
      fieldsError.terms = 'You must Agree to the Terms and Conditions and Privacy Notice'
    }

    if (!captcha) {
      fieldsError.reCaptcha = 'Captcha is not valid!'
    }

    if (Object.keys(fieldsError).length === 0) {
      const formData = {
        brandId: ALISI_BRAND_ID,
        email: fields.email,
        inquiryType: typeOfInquiry !== 'Others' ? typeOfInquiry : 'Inquiry',
        inquiry: fields.inquiry,
        location,
        firstName: fields.firstName,
        lastName: fields.lastName,
        middleName: fields.middleName,
        phoneNumber: fields.phoneNumber,
        message: fields.message
      }

      axios({
        method: 'post',
        url: `${API_URL}/contact-us`,
        data: formData
      })
        .then((res: any) => {
          handleReset()
          props.onOpenSubmit()
        })
        .catch((err: any) => {
          fieldsError.general = 'Submission Failed! Please try again later.'
          setErrors(fieldsError)
        })
    } else {
      setErrors(fieldsError)
    }
  }

  const handleReset = (): void => {
    setFields({
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      message: '',
      inquiry: ''
    })
    setLocation('')
    setTypeOfInquiry('')
    setTerms(false)
    setCaptcha(false)
  }

  return (
    <>
      <ContentNav links={links} active={0} />
      <Content>
        <h1>Contact Us</h1>
        {errors.general && (
          <small className="field-error fade-in">
            <i className="la la-exclamation-circle" /> {errors.general}
          </small>
        )}
        <div className="input-group-row">
          <div>
            <div className={`floating-input ${errors.firstName && 'error'}`}>
              <FloatingLabelInput
                onPaste={preventInput}
                name="firstName"
                value={fields.firstName}
                onChange={(e: any): void => {
                  const isAplha = alphaOnly(e)
                  if (!isAplha) {
                    return
                  }
                  handleChange(e)
                }}
                label="First Name"
              />
            </div>
            {errors.firstName && (
              <small className="field-error fade-in">
                <i className="la la-exclamation-circle" /> {errors.firstName}
              </small>
            )}
          </div>

          <div>
            <div className={`floating-input ${errors.middleName && 'error'}`}>
              <FloatingLabelInput
                onPaste={preventInput}
                name="middleName"
                value={fields.middleName}
                onChange={(e: any): void => {
                  const isAplha = alphaOnly(e)
                  if (!isAplha) {
                    return
                  }
                  handleChange(e)
                }}
                label="Middle Name"
              />
            </div>
          </div>
        </div>

        <div className="input-group-row">
          <div>
            <div className={`floating-input ${errors.lastName && 'error'}`}>
              <FloatingLabelInput
                onPaste={preventInput}
                name="lastName"
                value={fields.lastName}
                onChange={(e: any): void => {
                  const isAplha = alphaOnly(e)
                  if (!isAplha) {
                    return
                  }
                  handleChange(e)
                }}
                label="Last Name"
              />
            </div>
            {errors.lastName && (
              <small className="field-error fade-in">
                <i className="la la-exclamation-circle" /> {errors.lastName}
              </small>
            )}
          </div>

          <div>
            <div className={`floating-input ${errors.email && 'error'}`}>
              <FloatingLabelInput name="email" value={fields.email} onChange={handleChange} label="Email Address" />
            </div>
            {errors.email && (
              <small className="field-error fade-in">
                <i className="la la-exclamation-circle" /> {errors.email}
              </small>
            )}
          </div>
        </div>

        <div className="input-group-row">
          <div>
            <div className={`floating-input ${errors.location && 'error'}`}>
              <Dropdown
                filter
                filterValue={filterLocation}
                setFilter={setFilterLocation}
                defaultValue="Location"
                label="Location"
                name="location"
                value={location}
                active={locationActive}
                handleActive={(active: any) => setLocationActive(active)}
              >
                {!filterLocation ? (
                  <li onClick={() => handleLocationChange('')} value={''}>
                    None
                  </li>
                ) : filteredCountries.length === 0 ? (
                  <li onClick={() => handleLocationChange('')} value={''}>
                    No Results Found
                  </li>
                ) : null}
                {filteredCountries &&
                  filteredCountries.map((country: string, index: number) => {
                    return (
                      <li key={country} onClick={() => handleLocationChange(country)} value={country}>
                        {country}
                      </li>
                    )
                  })}
              </Dropdown>
            </div>
            {errors.location && (
              <small className="field-error fade-in">
                <i className="la la-exclamation-circle" /> {errors.location}
              </small>
            )}
          </div>

          <div>
            <div className={`floating-input ${errors.phoneNumber && 'error'}`}>
              <FloatingLabelInput
                onPaste={preventInput}
                maxLength="12"
                name="phoneNumber"
                value={fields.phoneNumber}
                onChange={(e: any): void => {
                  const isNumeric = numericOnly(e)
                  if (!isNumeric) {
                    return
                  }
                  handleChange(e)
                }}
                label="Phone Number"
              />
            </div>
            {errors.phoneNumber && (
              <small className="field-error fade-in">
                <i className="la la-exclamation-circle" /> {errors.phoneNumber}
              </small>
            )}
          </div>
        </div>

        <div className="input-group-row">
          <div>
            <div className={`floating-input ${errors.typeOfInquiry && 'error'}`}>
              <Dropdown
                defaultValue="Type of Inquiry"
                label="Type of Inquiry"
                name="type-of-inquiry"
                value={typeOfInquiry}
                active={typeOfInquiryActive}
                handleActive={(active: any) => setTypeOfInquiryActive(active)}
              >
                {/* <li onClick={() => handleTypeChange('')} value={''}>
                None
            </li> */}
                {typeOfInquiries.map((type: string, index: number) => {
                  return (
                    <li key={type} onClick={() => handleTypeChange(type)} value={type}>
                      {type}
                    </li>
                  )
                })}
              </Dropdown>
            </div>
            {errors.typeOfInquiry && (
              <small className="field-error fade-in">
                <i className="la la-exclamation-circle" /> {errors.typeOfInquiry}
              </small>
            )}
          </div>

          <div>
            {typeOfInquiry === 'Others' && (
              <div>
                <div className={`floating-input ${errors.inquiry && 'error'}`}>
                  <FloatingLabelInput name="inquiry" value={fields.inquiry} onChange={handleChange} label="Inquiry" />
                </div>
                {errors.inquiry && (
                  <small className="field-error fade-in">
                    <i className="la la-exclamation-circle" /> {errors.inquiry}
                  </small>
                )}
              </div>
            )}
          </div>
        </div>

        <div>
          <div className={`floating-input textarea ${errors.message && 'error'}`}>
            <div
              className={`is-floating-label ${messageFocus}`}
              onFocus={(): void => setMessageFocus('is-focused')}
              onBlur={(): any => !fields.message && setMessageFocus('')}
            >
              <label>Message</label>
              <textarea
                rows={4}
                onPaste={preventInput}
                onChange={(e: any): void => {
                  const isTrue = alphaNumericWithSpecialCharacters(e)
                  if (!isTrue) {
                    return
                  }
                  handleChange(e)
                }}
                name="message"
                value={fields.message}
              />
            </div>
          </div>
          {errors.message && (
            <small className="field-error fade-in">
              <i className="la la-exclamation-circle" /> {errors.message}
            </small>
          )}
        </div>

        <div className="form-agreement">
          <div className="terms-checkbox" onClick={() => handleTerms()}>
            {terms && <i className="la la-check" />}
          </div>{' '}
          <span>
            I have read and agreed with the <a onClick={props.onOpenPrivacy}>Privacy Notice</a> and{' '}
            <a onClick={props.onOpenTerms}>Terms and Conditions</a>
          </span>
        </div>
        {errors.terms && (
          <small className="field-error fade-in">
            <i className="la la-exclamation-circle" /> {errors.terms}
          </small>
        )}

        <div className="bottom-container">
          <div>
            <ReCAPTCHA
              sitekey="6LdPbLsZAAAAAKfFgRSCPS4hpp9-YeKDzrfWhsbr"
              onChange={(): void => {
                setCaptcha(true)
                setErrors((oldErrors) => ({
                  ...oldErrors,
                  reCaptcha: null
                }))
              }}
              onExpired={(): void => {
                setCaptcha(false)
                setErrors((oldErrors) => ({
                  ...oldErrors,
                  reCaptcha: null
                }))
              }}
              onErrored={(): void => {
                setCaptcha(false)
                setErrors((oldErrors) => ({
                  ...oldErrors,
                  reCaptcha: null
                }))
              }}
            />
            {errors.reCaptcha && (
              <small className="field-error fade-in">
                <i className="la la-exclamation-circle" /> {errors.reCaptcha}
              </small>
            )}
          </div>
          <div className="button-wrapper">
            <div className="submit" onClick={(): void => handleSubmit()}>
              <PaperPlaneIcon /> Submit
            </div>
          </div>
        </div>
      </Content>
    </>
  )
}

export default ContactForm
