import React, { useEffect } from 'react'

import './Dropdown.scss'

const CustomDropdown = (props: any) => {
  useEffect(() => {
    window?.addEventListener('click', function (e: any) {
      let idEL = document.getElementById(props.name) as HTMLElement
      if (!idEL?.contains(e.target)) {
        props.handleActive('')
        if (props.filter) {
          props.setFilter('')
        }
      }
    })
  }, [])

  const handleOpen = async () => {
    if (!props.active) {
      await props.handleActive(`${props.name}-active`)
      // if (props.filter) {
      //   inputFocus && inputFocus.focus()
      // }
      if (props.filter) {
        await props.setFilter('')
      }
    } else {
      if (props.filter) {
        await props.setFilter('')
      }
      await props.handleActive('')
    }
  }

  return (
    <div
      className={`wrapper-dropdown ${props.active}  ${props.value !== '' && props.active == '' ? 'after' : ''} ${props.name}`}
      id={props.name}
    >
      <div className="title" onClick={handleOpen}>
        <span>{props.value ? props.value : props.defaultValue}</span>
        {props.filter && (
          <input autoComplete="off" tabIndex={-1} id="filter" value={props.filterValue} onChange={(e) => props.setFilter(e.target.value)} />
        )}
      </div>
      {props.label || (props.label && props.value) ? <span className="label">{props.label}</span> : null}
      <ul className="dropdown fade-in-bottom">{props.children}</ul>
    </div>
  )
}

export default CustomDropdown
